body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

.contact {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1em;
  padding-top: 0;
}

.contact p {
  font-size: 2em;
}

.socialIcon {
  font-size: 10em;
}

.socialFacebook {
  color: #3b5998; 
}

.socialTwitter {
  color: #1DA1F2;
}

.socialInstagram {
  color: #df3268;
}

.socialEmail {
  color: #ffc20e;
}

.socialIcon:hover {
  color:black;
}

@media only screen and (max-width: 600px) {
  .socialIcon {
    font-size: 3em;
  }

  .contact p {
    font-size: 1.1em;
  }
}